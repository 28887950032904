import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, 
  ToggleButtonGroup, 
  ToggleButton, 
  Typography, 
  Button, 
  CircularProgress 
} from '@mui/material';
import Carousel from './Carousel'; // reusing your Carousel component from PricingPopup
import { HardwareList } from './PricingPopup';
import { LunexHeaderLogo, LunexHeaderLogoH1 } from './QuoteElements';
import logo from '../../images/lunexdirectlogo.jpg';


// A small helper to render each hardware item
const HardwareListItem = ({ item, quantity, type }) => {
  if (!item || quantity === 0) return null;
  return (
    <Box 
      display="flex" 
      alignItems="center" 
      justifyContent="space-between" 
      sx={{
        p: 1,
        border: '1px solid #ccc',
        borderRadius: 1,
        mb: 1
      }}
    >
      <Box display="flex" alignItems="center">
        <img 
          src={item.image} 
          alt={item.name} 
          style={{ width: 50, height: 50, borderRadius: 8 }} 
        />
        <Box ml={1}>
          <Typography variant="body1">{type}</Typography>
          <Typography variant="body2">{item.name}</Typography>
        </Box>
      </Box>
      <Typography variant="body1">Qty: {quantity}</Typography>
    </Box>
  );
};

const DesignLink = () => {
  // Get design id from the URL
  const { id } = useParams();

  // Local states for design data, pricing toggle, and sign-up status
  const [designData, setDesignData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pricingOption, setPricingOption] = useState('Cash');
  const [signupStatus, setSignupStatus] = useState({ loading: false, success: '', error: '' });
  // const [hardware, setHardware] = useState([]);
  // const hardware = [];

  // Fetch design data on mount using the id from the URL
  useEffect(() => {
    const fetchDesignData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate/getDesignEstimate/${id}`);
        if (response.ok) {
          const data = await response.json();
          setDesignData(data);
        } else {
          console.error("Failed to fetch design data");
        }
      } catch (error) {
        console.error("Error fetching design data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDesignData();

    // const hardware = [];
    // hardware.designImage = designData.images[0];
    // hardware.designInfo.screenshot3dEast = designData.images[1];
    // hardware.designInfo.screenshot3dSouth = designData.images[2];
    // hardware.designInfo.screenshot3dWest = designData.images[3];
    // hardware.designInfo.monthlyProduction = designData.monthlyProduction;
  }, [id]);

  // Handler for the pricing toggle change
  const handlePricingOptionChange = (event, newOption) => {
    if (newOption) {
      setPricingOption(newOption);
    }
  };

  // Handler for signing up now
  const handleSignUp = async () => {
    setSignupStatus({ loading: true, success: '', error: '' });
    try {
      const payload = {
        designId: id,
        // add any additional signup data here if needed
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        setSignupStatus({ loading: false, success: 'Sign Up Successful!', error: '' });
      } else {
        setSignupStatus({ loading: false, success: '', error: 'Sign Up Failed' });
      }
    } catch (error) {
      setSignupStatus({ loading: false, success: '', error: 'Error signing up' });
    }
  };

  // Render loading spinner or error message if needed
  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!designData) {
    return (
      <Typography variant="h6" align="center">
        Error loading design data.
      </Typography>
    );
  }

  // Determine the pricing value based on the toggle
  const pricingValue = pricingOption === 'Finance'
    ? designData.monthlyPayment
    : designData.totalCashPrice;

  const hardware = designData.hardware;

  const moduleQuantity = Math.ceil(hardware.systemSize / hardware.module.watts);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mr: 2, ml: 2, mb: 2, justifyContent: 'center', alignItems: 'center' }}>
      <LunexHeaderLogoH1 src={logo} alt={'Lunex Logo Image'}/>
      {/* <Typography variant='h5'>Your AI Generated Design</Typography> */}
      {/* Carousel at the top */}
      <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
      <Carousel 
        images={designData.images}
        monthlyProduction={designData.monthlyProduction}
      />
      </Box>

      {/* Three boxes: system size, annual production, energy offset */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '450px', mb: 5 }}>
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="h6">{(designData.systemSize/1000).toFixed(2)} kW</Typography>
          <Typography variant="subtitle2">System Size</Typography>
        </Box>
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="h6">{Number(designData.annualProduction).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })} kWh</Typography>
          <Typography variant="subtitle2">Annual Production</Typography>
        </Box>
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="h6">{designData.energyOffset}</Typography>
          <Typography variant="subtitle2">Energy Offset</Typography>
        </Box>
      </Box>
      {/* Hardware list items */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center', mb: 5, width: '100%', maxWidth: '450px', }}>
        {/* {designData.hardware && designData.hardware.map((item, index) => ( */}
          {/* <HardwareWrapper> */}
            <HardwareList item={hardware.module} quantity={moduleQuantity} name={hardware.module.name} type={"Modules"}/>
            <HardwareList 
            item={hardware.battery.name === 'Tesla Powerwall 3' && 
              hardware.inverter.name === 'Tesla Inverter' && 
              hardware.batteryQuantity > 0 
              ? hardware.battery : hardware.inverter} 
            quantity={hardware.battery.name === 'Tesla Powerwall 3' && 
              hardware.inverter.name === 'Tesla Inverter' && 
              hardware.batteryQuantity > 0 
              ? hardware.batteryQuantity : hardware.inverter.name === 'Tesla Inverter' && hardware.batteryQuantity === 0 
              ? Math.ceil(hardware.systemSize / (11.5*1000)) 
              : moduleQuantity
            } 
            name={
              hardware.battery.name === 'Tesla Powerwall 3' && 
              hardware.inverter.name === 'Tesla Inverter' && 
              hardware.batteryQuantity > 0 
              ? 'Tesla Powerwall 3' : hardware.inverter.name
            } 
            type={"Inverters"}/>
            <HardwareList item={hardware.battery} quantity={hardware.batteryQuantity} name={hardware.battery.name} type={"Battery"}/>
            <HardwareList item={hardware.services} quantity={hardware.serviceQuantity} name={hardware.services.name} type={"EV Charger"}/>
          {/* </HardwareWrapper> */}
        {/* ))} */}
      </Box>

      {/* Cash/Finance Toggle */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3, width: '100%' }}>
        <ToggleButtonGroup
          value={pricingOption}
          exclusive
          onChange={handlePricingOptionChange}
          size="medium"
        >
          <ToggleButton value="Cash">Cash</ToggleButton>
          <ToggleButton value="Finance">Finance</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Two boxes: Pricing and Existing Electric Bill */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 5, gap: '10px', width: '100%', maxWidth: '450px' }}>
        <Box sx={{ flex: 1, textAlign: 'center', borderRadius: '10px', backgroundColor: '#f5f5f5', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)' }}>
          <Typography variant="h4">
            ${pricingValue.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
          </Typography>
          <Typography variant="subtitle1">
            {pricingOption === 'Finance'
              ? 'New Monthly Payment'
              : 'Total Cash Price'}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, textAlign: 'center', borderRadius: '10px', backgroundColor: '#f5f5f5', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)' }}>
          <Typography variant="h4">
            ${designData.electricBill.toLocaleString()}
          </Typography>
          <Typography variant="subtitle1">Existing Electric Bill</Typography>
        </Box>
      </Box>

      

      {/* Sign Up Now button */}
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button 
          variant="contained" 
          onClick={handleSignUp} 
          disabled={signupStatus.loading}
        >
          {signupStatus.loading ? <CircularProgress size={24} /> : 'Sign Up Now'}
        </Button>
      </Box> */}

      {signupStatus.success && (
        <Typography variant="body1" color="success.main" align="center" sx={{ mt: 1 }}>
          {signupStatus.success}
        </Typography>
      )}
      {signupStatus.error && (
        <Typography variant="body1" color="error.main" align="center" sx={{ mt: 1 }}>
          {signupStatus.error}
        </Typography>
      )}
    </Box>
  );
};

export default DesignLink;
