import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CircularProgress, ToggleButtonGroup, ToggleButton, MenuItem, TextField, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import { FinanceData } from './FinanceData';
import Carousel from './Carousel';
import Lottie from 'lottie-react';
import gen1 from '../../images/lottie/gen1.json';
import gen2 from '../../images/lottie/gen2.json';
import gen3 from '../../images/lottie/gen3.json';
import gen4 from '../../images/lottie/gen4.json';
import LunexLogo from '../../images/lunex-power-logo.webp';
import LoadingJokes from './LoadingJokes';

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* height: calc(100% - 190px); */
  height: calc(100vh - 190px);
  /* height: auto; */
  width: 100vw;
  /* width: auto; */
  margin: 0 auto;
  /* margin-left: 20px; */
  margin-top: 72px;
  background-color: #f8f4f4;

  border-radius: 20px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;

  @media screen and (max-width: 1550px) {
    /* overflow-y: scroll; */
    height: calc(100vh - 190px);
  }

  @media screen and (max-width: 900px) {
    width: calc(100vw - 10px);
    /* height: calc(100vh - 132px); */
    height: 100%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 80px;
    /* overflow-y: scroll; */
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* height: 100%; */
  width: 100%;
  justify-content: center;
  max-width: 100%;
  /* background-color: green; */

  @media screen and (max-width: 1300px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  /* padding-bottom: 20px; */
  /* margin: 20px; */
  box-sizing: border-box;

  @media screen and (max-width: 900px){
    /* margin: 0; */
    /* width: 95%; */
  }
`;



const DesignWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  margin: auto 0;
  padding-top: 20px;
  /* background-color: green; */

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0;
  }
`

const DesignImage = styled.img`
  height: 100%;
  width: auto;
  display: flex;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);

  @media screen and (max-width: 1400px) {
    width: 90%;
    height: auto;
    max-height: 300px;
  }
`;

const CloseWrapper = styled.div`
  margin-left: auto;
  /* width: 99%; */
  /* display: flex; */
  /* justify-content: end;   */
  /* margin-top: 10px; */
`

const Spacer = styled.div`
  /* flex-grow: 1; */
  width: 30px;
  /* background-color: red; */
  height: 100%;
`

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  max-width: 100%;
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 150px;
`

const FinanceWrapper = styled.div`
  /* display: grid; */
  /* grid-template-columns: 1fr auto; */
  /* gap: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 900px) {
    /* grid-template-columns: 1fr; */
    margin: 0 auto;
    /* gap: 5px; */
    width: 98%;
  } 
`;

const DownPaymentField = styled(TextField)`
  width: 150px !important;

  @media screen and (max-width: 900px) {
    width: 100% !important;
    max-width: 150px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;


const TopContainerWrapper = styled.div`
  display: flex;
  
  justify-content: center;
  width: ${({$fin}) => ($fin ? '115%' : '100%')};

  @media screen and (max-width: 900px) {
    width: 100%;
  }

`

const TopContainer = styled.div`
  width: 100%;
  max-width: 500px;

  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: ${({bottombar}) => (bottombar ? '0' : '20px')};
  margin: 10px;
  text-align: center;

  @media screen and (max-width: 900px) {
    gap: 5px;
    margin: 5px;
    }
`;

const Value = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;

  @media screen and (max-width: 900px) {
    font-size: 1.1rem;
    }
`;

const Title = styled.div`
  font-size: 0.8rem;
  font-weight: normal;
  color: #555;

  @media screen and (max-width: 900px) {
    font-size: 12px;
    }
`;

const TextFieldsWrapper = styled.div`
  display: flex;
  flex-direction: ${({$submit}) => ($submit ? 'column' : 'row')};
  justify-content: space-between;
  /* align-items: center; */
  gap: 20px;
  /* max-width: ${({submit}) => (submit ? '250px' : '100%')}; */
  margin: ${({$submit}) => ($submit ? '40px' : '10px')};
`;

const StyledTypography = styled(Typography)`
  font-size: ${({$header}) => ($header ? '1.2rem !important' : '0.85rem !important')};
  color: ${({$blue}) => ($blue ? '#1976d2' : 'black')};
  font-weight: ${({$header}) => ($header ? 'bold !important' : 'normal')};
  margin: 5px 0;
  text-align: ${({$design}) => ($design ? 'center' : 'left')};
  text-decoration: ${({$strike}) => ($strike ? 'line-through' : 'none')};
`;

const StyledHeader = styled(Typography)`
  padding-left: ${({$design}) => ($design ? '0' : '35px')};
  font-size: 1.5rem !important;
  color: #000;
  font-weight: bold !important;
  text-align: center;
  flex-grow: 1;
  /* background-color: green; */
`;

const StyledTypography2 = styled(Typography)`
  font-size: 0.7rem !important;
  color: grey !important;
  margin: 10px 0;
  text-align: center;
`;



const FinanceFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  position: relative;
  

  @media screen and ((max-width: 1300px) or (max-height: 630px)) {
    flex-direction: column; // Switch to column direction on smaller screens
  }
`;

const Column = styled.div`
  flex: 1;
  width: 100%;
  align-items: center;
  margin: 5px;
  position: relative;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const MainColumn = styled.div`
  flex: 1;
  padding-top: 10px;
  margin: 0 auto;

  &:nth-child(1) {
    order: 1; // Always first
    @media screen and (min-width: 1300px) and (min-height:630px) {
      position: fixed; // Fix to its initial position
      left: 20px; // Adjust left position as needed
    }

  }
  
  &:nth-child(2) {
    order: 3; // Will be last on smaller screens
    @media screen and (min-width: 1300px) and (min-height:630px) {
      position: relative;
      flex: 0 0 auto; // Ensure the width of the center column is automatically sized and centered
      margin: 0 auto; // Center the column horizontally within the flex container
      max-width: 450px;
      padding-bottom:50px;
    }

  }
  
  &:nth-child(3) {
    order: 2; // Will be second on smaller screens
    @media screen and (min-width: 1300px) and (min-height:630px){
      position: fixed; // Fix to its initial position
      right: 10px; // Adjust right position as needed
      pointer-events: none;
    }

  }

  @media screen and ((max-width: 1300px) or (max-height:630px)){
      width: 95%;
      max-width: 500px;
    }
`;



const CarouselWrapper = styled.div`
  /* position: fixed;
  left: 40px; */
  /* top: 0;
  right: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: red; */
  @media screen and (max-width: 1300px) {
    position: relative;
    left: 0;
  }
  
`

const PricingWrapper = styled.div`
  max-height: calc(100vh - 300px);
  /* height: 100%; */
  /* overflow-y: hidden; */
  padding: 10px;

  @media screen and (max-width: 900px) {
    max-height: 100%;
  }
`

const ButtonWrapper = styled.div`
  min-width: 200px;
  margin: 20px;
  display: flex;
  gap:10px;
  justify-content: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: ${({ $back }) => ($back ? 'white' : '#1976d2')} !important;
  color: ${({ $back }) => ($back ? '#1976d2' : 'white')} !important;
  border: ${({ $back }) => ($back ? '2px solid #1976d2 !important' : 'none')};

  &:hover {
    background-color: ${({ $back }) => ($back ? '#e3f2fd' : '#1565c0')} !important;
  }
`;

const SubmitMessageWrapper = styled.div`
    width: 100%;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media screen and (max-width: 480px) {
      width: 80%;
      margin: 0 auto;
    }

`

const SubmitMessageText = styled.div`
  text-align: center;
  color: ${({$success}) => ($success ? '#388e3c' : '#d32f2f')};
  font-size: 1.5rem;
  margin-top: 0.25rem;
`

const ErrorContainer = styled.div`
  /* height: 350px; */
  width: 80%;
  display: flex;
  justify-content: center;
  /* padding-left: 20px; */
  /* min-width: 500px; */
  
  @media screen and (max-width: 900px) {
    padding-top: 20px;
    width: 80%;
    margin: 0 auto;
    height: auto;
    max-height: 200px;
    min-width: 0;
  }

`;

const HardwareOuterWrapper = styled.div`
  /* position: fixed;
  right: 40px; */

  @media screen and (max-width: 1300px) {
    position: relative;
    right: 0;
  }

`;

const HardwareWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center; */
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 95%;
  max-width: 450px;
  /* padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 20px; */
  /* background-color: green; */
  margin: 0 auto;
  padding-bottom: 20px;
`;

const HardwareRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  max-width: 350px;
  width: 100%;
  background-color: #f5f5f5;

  @media screen and (max-width: 900px) {
    width: 100%;
  }


`;

const HardwareInfoColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 25px;
`;

const HardwareInfoColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const HardwareImage = styled.img`
  width: 50px;
  height: 50px;
  /* margin-right: 20px; */
  border-radius: 8px;
`;


export const LoadingWrapper = styled.div`
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #ccc; */
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  overflow: hidden;
  padding: 10px; 
  box-sizing: border-box;
  /* padding-top: ${({$joke}) => ($joke ? '120px' : '5px')}; */
`;

export const LoadingMessage = styled.div`
  font-size: 24px;
  font-family: monospace;
  white-space: pre-wrap; /* Allows wrapping and preserves spaces/newlines */
  word-wrap: break-word; /* Breaks long words to prevent overflow */
  overflow-wrap: break-word; /* Ensures text wraps within the container */
  line-height: 1.5; /* Improves readability with better spacing between lines */
  max-width: 100%; /* Ensures the text stays within the wrapper's width */
  /* height: 100%; */
  
`;

export const HardwareList = ({ item, quantity, name, type }) => {
  if (!item || quantity === 0) return null;

  return (
    <HardwareRow>
      <HardwareImage src={item.image} alt={item.name} />
      <HardwareInfoColumn>
        <StyledTypography $design>{type}</StyledTypography>
        <StyledTypography $header $design>{name}</StyledTypography>
      </HardwareInfoColumn>
      <HardwareInfoColumnRight>
        <StyledTypography $design>Qty</StyledTypography>
        <StyledTypography $header $design>{quantity}</StyledTypography>
      </HardwareInfoColumnRight>
      
      {/* <StyledTypography header>{quantity}</StyledTypography> */}
    </HardwareRow>
  );
};

const PricingPopup = ({ 
  hardware, 
  onClick, 
  updateHardware, 
  updateLender, 
  updateProduct, 
  responseState, 
  formState,
  satelliteImageUrl,
  setDesignLocked,
  setDesignStatus,
  designStatus
}) => {
  const [toggleView, setToggleView] = useState('Cash');
  const [paymentTypeSelected, setPaymentTypeSelected] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleToggleChange = (event, newView) => {
    if (newView !== null) {
      setToggleView(newView);
    }
  };

  const handleErrorButton = () => {
    setDesignStatus('errored');
  };

  async function handleSelectPaymentType() {
    setPaymentTypeSelected(!paymentTypeSelected);
    setError('');
  }

  async function handleSignUpButton() {
    const updatedPaymentType = toggleView;
    updateHardware('paymentType', updatedPaymentType);
    // console.log("hardware.paymentType", updatedPaymentType);

    const updatedHardware = { 
      ...hardware, 
      paymentType: updatedPaymentType 
    };

    await handleSignUp(updatedHardware);
  }

  async function handleSignUp(updatedHardware) {
    setSubmitting(true);
    setError(false);

    // const adders = [];
    // const adder = hardware.serviceQuantity > 0 ? "Wallbox EV Charger (Pulsar Plus Level 2 - 40A) - FL Only" : "";
    // if(adder !== ""){
    //   adders.push(adder);
    // }

    // const financeType = toggleView === 'Finance' ? "Climate First Bank" : "Cash Finance";

    const data = JSON.stringify({
      "address": formState.address,
      // "adders": adders,
      // "financeType": financeType,
      "hardware": updatedHardware,
    });
    
    const config = {
      method: 'put',
      headers: { 
        'Content-Type': 'application/json', 
      },
      body: data
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate`, config);
  
    if (response.ok) {
      setSuccess('Thank you! You will receive an email shortly!');
    } else {
      setError('Something went wrong');
    }
    
    setSubmitting(false);
  }

  const moduleQuantity = Math.ceil(hardware.systemSize / hardware.module.watts);

  return (
    <>
      <PopupContainer>
        <HeaderWrapper>
          <Spacer />
          <StyledHeader $design>
            {designStatus === 'errored' ? 'Your System Estimate' : 'Your System'}
          </StyledHeader>
          {(designStatus === 'completed' || designStatus === 'errored') ? (
              <CloseWrapper>
                <IconButton onClick={onClick}>
                  <CloseIcon />
                </IconButton>
              </CloseWrapper>
            ) :
            <Spacer />
          }
        </HeaderWrapper>
        <ContentWrapper>
          {(designStatus !== 'completed' && designStatus !== 'errored') ? (
            <>
              {designStatus === 'failedFinal' ? 
                <DesignWrapper>     
                  <Column>
                    <DesignImage src={hardware.designImage} alt="Design Preview" />
                  </Column>
                  <Column>
                    <LoadingWrapper>
                      <LoadingMessage>Oops! Looks like our AI isn't quite ready to take over the world—or finish your design. But don't worry, a human expert will step in to handle it. Click continue to see pricing estimate!</LoadingMessage>
                      <ButtonWrapper>
                        <StyledButton onClick={handleErrorButton}>Continue</StyledButton>
                      </ButtonWrapper>
                    </LoadingWrapper>
                    
                  </Column>
                </DesignWrapper>
                :
                <DesignWrapper>
                  <Column>
                    <DesignImage src={hardware.designImage} alt="Design Preview" />
                  </Column>
                  <Column>
                    <LoadingWrapper $joke>
                      <LoadingMessage>
                        <LoadingJokes />
                      </LoadingMessage>
                    </LoadingWrapper>
                  </Column>
                </DesignWrapper>
              }
            </>
          ) : (
            <ColumnContainer>
            
              <MainColumn>
                <CarouselWrapper>
                  <Carousel 
                    hardware={hardware}
                    designStatus={designStatus}
                  />
                  <TopContainerWrapper $fin>
                    <TopContainer>
                      <Column>
                        <Value>{(hardware.systemSize/1000).toFixed(2)} kW</Value>
                        <Title>System Size</Title>
                      </Column>
                      <Column>
                        <Value>{Math.round(hardware.annualProduction ? hardware.annualProduction : responseState.solarProductionMonthly*12).toLocaleString()} kWh</Value>
                        <Title>Annual Production</Title>
                      </Column>
                      <Column>
                        <Value>{Math.round(hardware.offset)} %</Value>
                        <Title>Energy Offset</Title>
                      </Column>
                    </TopContainer>
                  </TopContainerWrapper>
                
                </CarouselWrapper>
                
                
              </MainColumn>
              <MainColumn>
              
                <PricingWrapper>
                {!success && 
                  <>
                      <ToggleWrapper>
                        <StyledToggleButtonGroup
                          value={toggleView}
                          exclusive
                          onChange={handleToggleChange}
                          aria-label="Toggle Pricing View"
                        >
                          {(!paymentTypeSelected || (paymentTypeSelected && toggleView === "Cash")) && (
                            <StyledToggleButton value="Cash" aria-label="Cash">
                              Cash
                            </StyledToggleButton>
                          )}
                          {(!paymentTypeSelected || (paymentTypeSelected && toggleView === "Finance")) && (
                            <StyledToggleButton value="Finance" aria-label="Finance">
                              Finance
                            </StyledToggleButton>
                          )}
                          {/* <StyledToggleButton value="Lease" aria-label="Lease">
                            Lease
                          </StyledToggleButton> */}
                        </StyledToggleButtonGroup>
                        
                      </ToggleWrapper>
                  </>
                }
                {toggleView === 'Lease' && (
                <>
                  <TopContainerWrapper $fin>
                    <TopContainer>
                      <Column>
                        <Value>30 Years</Value>
                        <Title>Finance Term</Title>
                      </Column>
                      <Column>
                        <Value>{hardware.selectedProduct.apr} %</Value>
                        <Title>APR</Title>
                      </Column>
                      <Column>
                        <Value>${hardware.financedMonthlyPaymentAfterTaxCredit.toFixed(2)}*</Value>
                        <Title>Financed Payment /mo</Title>
                      </Column>
                    </TopContainer>
                  </TopContainerWrapper>
                  <TextFieldsWrapper>
                    <StyledTypography $header $blue>
                      Your Monthly Bill Goes Down To
                    </StyledTypography>
                    <StyledTypography $header $blue>
                      ${hardware.financedMonthlyPaymentAfterTaxCredit.toFixed(2)}
                    </StyledTypography>
                  </TextFieldsWrapper>
                  <TextWrapper>
                    <StyledTypography2>
                      Lease payments may vary based on available promotions and credit approval. Consult with your leasing representative for exact terms and rates.
                    </StyledTypography2>
                  </TextWrapper>
                </>
              )}
                {toggleView === 'Finance' && (
                <>
                    {/* Finance View Content */}
                    {!paymentTypeSelected &&
                      
                      <FinanceWrapper>
                        <br />
                        <>
                          <StyledTypography>Would you like to add a down payment?</StyledTypography>
                          <DownPaymentField
                            label="Down Payment"
                            type="number"
                            value={hardware.downPayment}
                            onChange={(e) => {
                              let value = e.target.value;
                          
                              if (value === "") {
                                updateHardware("downPayment", "");
                              } else {
                                value = parseInt(value, 10);
                          
                                // If the parsed value is greater than totalCost, set it to totalCost
                                if (value > (hardware.totalCost - hardware.totalCost * 0.3)) {
                                  updateHardware("downPayment", Math.round(hardware.totalCost - hardware.totalCost * 0.3));
                                } else if (value < 0 || isNaN(value)) {
                                  // If the value is negative or NaN, set it to 0
                                  updateHardware("downPayment", 0);
                                } else {
                                  // Otherwise, set the value entered by the user
                                  updateHardware("downPayment", value);
                                }
                              }
                            }}
                            onBlur={(e) => {
                              let value = e.target.value;
                              if (isNaN(value) || value === "") {
                                updateHardware("downPayment", 0);  // Set to 0 when blurred with empty or NaN value
                              }
                            }}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </>
                      
                    </FinanceWrapper>
                    }
                    <TopContainerWrapper>
                      <TopContainer>
                        <Column>
                          <Value>30 Years</Value>
                          <Title>Finance Term</Title>
                        </Column>
                        <Column>
                          <Value>{hardware.selectedProduct.apr} %</Value>
                          <Title>APR</Title>
                        </Column>
                        {hardware.downPayment > 0 &&
                          <Column>
                            <Value>${hardware.downPayment.toLocaleString()}</Value>
                            <Title>Down Payment</Title>
                          </Column>
                        }
                        
                        <Column>
                          <Value>${hardware.financedMonthlyPaymentAfterTaxCredit.toFixed(2)}*</Value>
                          <Title>Financed Payment /mo</Title>
                        </Column>
                      </TopContainer>
                    </TopContainerWrapper>
                    <TextFieldsWrapper>
                      <StyledTypography>
                        Your Monthly Bill Starts At
                      </StyledTypography>
                      <StyledTypography>
                        ${hardware.financedMonthlyPaymentPreTaxCredit.toFixed(2)}
                      </StyledTypography>
                    </TextFieldsWrapper>
                    <TextFieldsWrapper>
                      <StyledTypography $header $blue>
                        Your Monthly Bill Goes Down To
                      </StyledTypography>
                      <StyledTypography $header $blue>
                        ${hardware.financedMonthlyPaymentAfterTaxCredit.toFixed(2)}
                      </StyledTypography>
                    </TextFieldsWrapper>

                    {!paymentTypeSelected &&
                      <>
                        <TextWrapper>
                        <StyledTypography2>
                        The estimated payment is based on the current APR and may vary until your credit application is approved. Installation costs are included, though additional upgrades, such as electrical panel work or hidden conduit, may be required. While rebates and incentives may be available, eligibility is not guaranteed. A Federal Investment Tax Credit (ITC) may apply to solar systems, storage batteries, and EV chargers, provided your tax liability meets or exceeds the credit amount. Consult a tax advisor to confirm your eligibility for any rebates or tax credits.                  </StyledTypography2>
                      </TextWrapper>
                      <br />
                      <TextWrapper>
                        <StyledTypography2>
                        I authorize Lunex Direct to reach out to me regarding this inquiry, as well as to provide additional information about Lunex products and services using the contact details I've provided. I acknowledge that calls or texts may utilize automated or computer-assisted dialing systems, or pre-recorded messages. Standard message and data rates may apply.                
                        </StyledTypography2>
                      </TextWrapper>
                      </> 
                    }
                    
                    {paymentTypeSelected &&
                      <TextFieldsWrapper $submit>
                        {designStatus === 'errored' ? 
                          <StyledTypography $design>
                          Click Submit and we will reach out to you at your email:
                          </StyledTypography>
                          :
                          <>
                            <StyledTypography $design>
                            Click Submit to get started now.
                            </StyledTypography>
                            <StyledTypography $design>
                            We will follow up with you at:
                            </StyledTypography>
                          </>
                        }
                        <StyledTypography $design>
                          <strong>{formState.email}</strong>
                        </StyledTypography>
                      </TextFieldsWrapper>
                    }
                  </>
                )}
                {toggleView === 'Cash' &&
                  <>
                    <TopContainerWrapper>
                      <TopContainer>
                        <Column>
                          <Value>${Number(hardware.totalCost.toFixed(0)).toLocaleString()}</Value>
                          <Title>Total Cost</Title>
                        </Column>
                        <Column>
                          <Value>${Number(hardware.totalAfterTaxCredit.toFixed(0)).toLocaleString()}</Value>
                          <Title>Total After Tax Credit</Title>
                        </Column>
                      </TopContainer>
                    </TopContainerWrapper>
                    <FinanceFieldsWrapper>
                      <TextFieldsWrapper>
                        <StyledTypography>
                          {hardware.systemSize / 1000} kW {hardware.module.name} | {hardware.inverter.name}
                        </StyledTypography>
                        <StyledTypography>
                          ${Math.round(hardware.solarCost).toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                      {hardware.batteryQuantity !== 0 && (
                        <>
                          <TextFieldsWrapper>
                            <StyledTypography>
                              {hardware.batteryQuantity} - {hardware.battery.name}{hardware.batteryQuantity === 1 ? '' : "'s"}
                            </StyledTypography>
                            <StyledTypography>
                              ${Math.round(hardware.batteryCost).toLocaleString()}
                            </StyledTypography>
                          </TextFieldsWrapper>
                        </>
                      )}
                      {hardware.serviceQuantity !== 0 && (
                        <>
                          <TextFieldsWrapper>
                            <StyledTypography>
                              {hardware.serviceQuantity} - {hardware.services.name}{hardware.serviceQuantity === 1 ? '' : "'s"}
                            </StyledTypography>
                            <StyledTypography>
                              ${Math.round(hardware.serviceCost).toLocaleString()}
                            </StyledTypography>
                          </TextFieldsWrapper>
                        </>
                      )}
                      <TextFieldsWrapper>
                        <StyledTypography>
                          Total Price Before Incentives
                        </StyledTypography>
                        <StyledTypography>
                          ${Math.round(hardware.totalCost).toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                      <TextFieldsWrapper>
                        <StyledTypography>
                          Federal Tax Credit (30%)
                        </StyledTypography>
                        <StyledTypography>
                          -${Math.round(hardware.totalCost * 0.3).toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                      <TextFieldsWrapper>
                        <StyledTypography $header $blue>
                          Price After Incentives
                        </StyledTypography>
                        <StyledTypography $header $blue>
                          ${Math.round(hardware.totalCost - hardware.totalCost * 0.3).toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                    </FinanceFieldsWrapper>
                    {!paymentTypeSelected &&
                      <>
                        <TextWrapper>
                          <StyledTypography2>
                          Installation fees are covered, but some additional work may be needed, such as upgrading the electrical main panel or installing hidden conduit. Lunex Direct cannot guarantee these costs or your qualification for rebates and incentives. Be sure to consult a tax professional to confirm your eligibility.
                          </StyledTypography2>
                        </TextWrapper>
                        <br />
                        <TextWrapper>
                          <StyledTypography2>
                          A Federal Investment Tax Credit (ITC) is typically available for those who purchase solar systems. Eligible expenses may include solar paired with storage batteries, such as Powerwall or other systems, and EV chargers. To claim the tax credit, your tax liability must meet or exceed the credit amount. Lunex Direct does not guarantee eligibility or the amount of any incentives. Please consult a tax professional for specific details regarding your eligibility.                  
                          </StyledTypography2>
                        </TextWrapper>
                        <br />
                        <TextWrapper>
                          <StyledTypography2>
                          I authorize Lunex Direct to reach out to me regarding this inquiry, as well as to provide additional information about Lunex products and services using the contact details I've provided. I acknowledge that calls or texts may utilize automated or computer-assisted dialing systems, or pre-recorded messages. Standard message and data rates may apply.                
                          </StyledTypography2>
                        </TextWrapper>
                      </>
                    }
                    
                    {paymentTypeSelected &&
                      <TextFieldsWrapper $submit>
                        {designStatus === 'errored' ? 
                          <StyledTypography $design>
                          Click Submit and we will reach out to you at your email:
                          </StyledTypography>
                          :
                          <>
                            <StyledTypography $design>
                            Click Submit to get started now.
                            </StyledTypography>
                            <StyledTypography $design>
                            We will follow up with you at:
                            </StyledTypography>
                          </>
                        }
                        <StyledTypography $design>
                          <strong>{formState.email}</strong>
                        </StyledTypography>
                      </TextFieldsWrapper>
                    }
                  </>
                }
                
                {!success && !paymentTypeSelected &&
                  <ButtonWrapper>
                    {submitting ? <CircularProgress size={24}/> : <StyledButton onClick={handleSelectPaymentType}>Confirm Payment Type</StyledButton>}
                  </ButtonWrapper>
                }
                {!success && paymentTypeSelected &&
                  <>
                  <ButtonWrapper>
                    <StyledButton onClick={handleSelectPaymentType} $back>Back</StyledButton>
                  {/* </ButtonWrapper>
                  <ButtonWrapper> */}
                    {submitting ? <CircularProgress size={24}/> : <StyledButton onClick={handleSignUpButton}>Submit</StyledButton>}
                  </ButtonWrapper>
                  </>
                }
                {(success || error) &&
                  <SubmitMessageWrapper>
                    {success && <SubmitMessageText $success>{success}</SubmitMessageText>}
                    {error && <SubmitMessageText>{error}</SubmitMessageText>}
                  </SubmitMessageWrapper>
                }
                <br />
                </PricingWrapper>

              </MainColumn>
              <MainColumn>
                <HardwareOuterWrapper>
                  <HardwareWrapper>
                    <HardwareList item={hardware.module} quantity={moduleQuantity} name={hardware.module.name} type={"Modules"}/>
                    <HardwareList 
                    item={hardware.battery.name === 'Tesla Powerwall 3' && 
                      hardware.inverter.name === 'Tesla Inverter' && 
                      hardware.batteryQuantity > 0 
                      ? hardware.battery : hardware.inverter} 
                    quantity={hardware.battery.name === 'Tesla Powerwall 3' && 
                      hardware.inverter.name === 'Tesla Inverter' && 
                      hardware.batteryQuantity > 0 
                      ? hardware.batteryQuantity : hardware.inverter.name === 'Tesla Inverter' && hardware.batteryQuantity === 0 
                      ? Math.ceil(hardware.systemSize / (11.5*1000)) 
                      : moduleQuantity
                    } 
                    name={
                      hardware.battery.name === 'Tesla Powerwall 3' && 
                      hardware.inverter.name === 'Tesla Inverter' && 
                      hardware.batteryQuantity > 0 
                      ? 'Tesla Powerwall 3' : hardware.inverter.name
                    } 
                    type={"Inverters"}/>
                    <HardwareList item={hardware.battery} quantity={hardware.batteryQuantity} name={hardware.battery.name} type={"Battery"}/>
                    <HardwareList item={hardware.services} quantity={hardware.serviceQuantity} name={hardware.services.name} type={"EV Charger"}/>
                  </HardwareWrapper>
                </HardwareOuterWrapper>
              </MainColumn>
              
            </ColumnContainer>
          )}
        </ContentWrapper>
      </PopupContainer>
    </>
  );
};

export default PricingPopup;