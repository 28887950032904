import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { CircularProgress, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Navigation, Autoplay } from 'swiper/modules';

const SwiperStyled = styled.div`
  max-width: 100%;
    .swiper-button-next,
    .swiper-button-prev {
        background-color: rgba(0, 0, 0, 0.5); 
        color: white; 
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        font-size: 18px;
    }
`;

const Carousel = ({ hardware, designStatus, images, monthlyProduction }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [slidesData, setSlidesData] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (designStatus === 'completed' && hardware?.designInfo) {
      const slides = [
        hardware.designImage,
        hardware.designInfo.screenshot3dEast,
        hardware.designInfo.screenshot3dSouth,
        hardware.designInfo.screenshot3dWest,
      ];
      setSlidesData(slides);

      // Prepare chart data
      const productionData = hardware.designInfo.monthlyProduction.map((value, index) => ({
        month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][index],
        Production: parseInt(value),
      }));
      setChartData(productionData);

      setIsLoading(false);
    }
    else if(images && monthlyProduction){
      console.log('images', images);
      setSlidesData(images);
      // Prepare chart data
      const productionData = monthlyProduction.map((value, index) => ({
        month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][index],
        Production: parseInt(value),
      }));
      setChartData(productionData);

      setIsLoading(false);
    }
    else if(designStatus === 'errored'){
      setSlidesData([hardware.designImage]);
      setIsLoading(false);
    }
  }, [designStatus, hardware, images, monthlyProduction]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '350px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <SwiperStyled>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        navigation={designStatus === 'errored' ? false : true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        modules={[Navigation, Autoplay]}
        className="mySwiper"
        style={{ width: 'auto', height: '300px', maxWidth: '350px' }}
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <img
              src={slide}
              alt={`Home Design ${index}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
          </SwiperSlide>
        ))}
        {designStatus !== 'errored' &&
          <SwiperSlide>
            <div style={{ height: '100%', width: '100%' }}>
              <Typography header align="center">Production (kWh)</Typography>
              <ResponsiveContainer width="100%" height="89%">
                <BarChart data={chartData}>
                  <XAxis dataKey="month" />
                  <YAxis domain={[0, 'auto']} />
                  <Tooltip />
                  <Bar dataKey="Production" fill="#1976d2" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </SwiperSlide>
        }
        
      </Swiper>
    </SwiperStyled>
  );
};

export default Carousel;
